var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data && !_vm.isLoadingOrder)?_c('div',{staticClass:"section-summary"},[_c('CheckoutProductItems',{staticClass:"mb-4",attrs:{"isDefault":false,"data":[
      {
        imageUrl: _vm.data.product_main.image_url,
        name: _vm.data.product_main.name,
        price_ship: _vm.data.product_main.price,
      },
      ..._vm.bundles,
    ]}}),_c('CheckoutSummary',{attrs:{"isDefault":false,"cartSummaryData":_vm.cartSummaryData,"isDeposit":_vm.isDeposit,"isPickupAtStore":!_vm.isDelivery,"shipDate":_vm.shippingDate}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }