<template>
  <div v-if="data && !isLoadingOrder" class="section-summary">
    <CheckoutProductItems
      :isDefault="false"
      :data="[
        {
          imageUrl: data.product_main.image_url,
          name: data.product_main.name,
          price_ship: data.product_main.price,
        },
        ...bundles,
      ]"
      class="mb-4"
    />

    <CheckoutSummary
      :isDefault="false"
      :cartSummaryData="cartSummaryData"
      :isDeposit="isDeposit"
      :isPickupAtStore="!isDelivery"
      :shipDate="shippingDate"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import isBnn from '@/config/isBnn'
import colorBnn from '@/config/color'
import cartSummary from '@/mixins/cartSummary'
import { ORDER_STATUS } from '@/config/order'

export default {
  name: 'CheckoutModalConfirmOrderContent',

  mixins: [cartSummary],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    CheckoutProductItems: () =>
      import('@/components/studio7/CheckoutProductItems'),
    CheckoutSummary: () =>
      import('@/components/studio7/CheckoutSummary'),
  },

  data() {
    return {
      // Import
      colorBnn,
      isBnn,
      ORDER_STATUS,

      // Data
      form: {
        couponCode: '',
      },
      formCheckSummary: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        address: null,
        sub_district: null,
        district: null,
        province: null,
        postcode: null,
        // Notes: Hide default value from local storage
        // first_name: localStorage.getItem('firstName') || null,
        // last_name: localStorage.getItem('lastName') || null,
        // phone: localStorage.getItem('phoneNumber') || null,
        // email: localStorage.getItem('email') || null,
        // address: localStorage.getItem('address') || null,
        // sub_district: localStorage.getItem('sub_district') || null,
        // district: localStorage.getItem('district') || null,
        // province: localStorage.getItem('province') || null,
        // postcode: localStorage.getItem('postcode') || null,
        product_id: '',
        bundle_products: [],
        delivery: false,
        is_delivery: false,
      },
      freebieListByCouponModal: false,
    }
  },

  computed: {
    ...mapState({
      selectSKU: state => state.model.selectSKU,
      bundleList: state => state.model.bundleList,
      campaign: state => state.campaign.current,

      //   data: state => state.order.data,
      isLoadingOrder: state => state.order.isLoading,
    }),

    isDelivery() {
      return this.data?.is_delivery
    },

    isDeposit() {
      return this.data?.is_deposit
    },

    isPayAtStore() {
      return this.data?.is_pay_at_store
    },

    isPayAtStoreTradeIn() {
      return (
        this.data?.pay_at_store_detail?.order_type == 'with_tradein'
      )
    },

    isPayAtStorePromotion() {
      return this.data?.pay_at_store_detail?.order_type == 'with_plan'
    },

    ...mapGetters({
      additionalProduct: 'model/getAdditionalProduct',
      appleCare: 'model/getAppleCare',
      cartItems: 'cart/cartItems',
      productItem: 'cart/productItem',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      bundleItems: 'cart/bundleItems',
      discountOnTop: 'cart/discountOnTop',
      deliveryItems: 'cart/deliveryItems',
      getBundleDelivery: 'model/getBundleDelivery',
      accessoryBundles: 'model/getAccessoryBundles',
      // getFreebieHandraiser
      handraiserFreebies: 'model/getHandraiserFreebies' ?? [],
    }),

    finalPrice() {
      return (
        this.data?.pay_at_store_detail?.paid_amount ||
        this.data?.total_price ||
        0
      )
    },

    additionalDiscountLabel() {
      return this.isPayAtStoreTradeIn
        ? 'ส่วนลดจาก Trade-in'
        : this.isPayAtStorePromotion
        ? 'ส่วนลดจากโปรโมชั่นเบอร์ทรู'
        : 'ส่วนลดเพิ่มเติม'
    },

    cartSummaryData() {
      return {
        before_discount: this.data?.before_discount,
        summary_discount: Number(this.data?.discount),
        product_price_deposit_summary: this.data?.deposit,
        remain_amount_original: this.data?.remaining,
        final_price: this.finalPrice,

        // Additional discount
        additional_discount: this.isPayAtStoreTradeIn
          ? Number(this.data?.pay_at_store_detail?.voucher_amount)
          : this.isPayAtStorePromotion
          ? //   ? this.data?.pay_at_store_detail?.promotion_discount
            this.data?.pay_at_store_detail
              ?.with_plan_promotion_discount
          : 0,

        advance_payment: this.data?.pay_at_store_detail
          ?.with_plan_advance_payment,
        advance_payment_optional: this.data?.pay_at_store_detail
          ?.with_plan_advance_payment_optional,
      }
    },

    bundles() {
      return (
        this.data?.bundle_products?.filter(
          i => i?.type !== 'delivery',
        ) ?? []
      )
    },

    receiveBranchName() {
      return this.data?.receive_branch_name ?? ''
    },

    receiveBranchAddress() {
      return this.data?.receive_branch_address
        ? `${this.data?.receive_branch_address?.address} ${this.data?.receive_branch_address?.province} `
        : ''
    },

    shippingDate() {
      return (
        this.data?.shipping_date ??
        this.data?.receive_date ??
        'ไม่พบข้อมูล'
      )
    },

    shipDate() {
      return (
        this.selectSKU.shipping_date ||
        this.campaign?.data?.shipdate ||
        '-'
      )
    },

    fullAddress() {
      return `เลขที่ ${this.data?.address} แขวง/ตำบล ${this.data?.sub_district} เขต/อำเภอ ${this.data?.district} ${this.data?.province} ${this.data?.postcode}`
    },

    fullTaxAddress() {
      return `เลขที่ ${this.data?.tax_address?.address} แขวง/ตำบล ${this.data?.tax_address?.sub_district} เขต/อำเภอ ${this.data?.tax_address?.district} ${this.data?.tax_address?.province} ${this.data?.tax_address?.postcode}`
    },

    fullBillAddress() {
      return `เลขที่ ${this.data?.bill_address?.address} แขวง/ตำบล ${this.data?.bill_address?.sub_district} เขต/อำเภอ ${this.data?.bill_address?.district} ${this.data?.bill_address?.province} ${this.data?.bill_address?.postcode}`
    },

    depositNumber() {
      return this.data?.deposit_number ?? 'กำลังดำเนินการ'
    },
  },

  async mounted() {},

  methods: {
    contactUs() {
      // TODO: Redirect to contact page new page
      window.open(
        'https://www.studio7thailand.com/th/pages/contact-us',
        '_blank',
      )
    },

    goBack() {
      let slug =
        this.$route.params?.slug ||
        localStorage.getItem('productLatestVisit')

      this.$router.push({
        name: 'ProductDetail',
        params: {
          slug: slug,
        },
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.checkout-container {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 auto;

    @media screen and (min-width: 1024px) {
        display: grid;
        grid-template-columns: minmax(min-content, calc(50% + 72px)) 1fr;

        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    > .left-container {
        width 100%
        max-width: 570px;
        background-color: #fff
        border-right: none;
        margin: 0 auto;
        min-height: 100dvh;

        @media screen and (min-width: 1024px) {
            max-width: 100%;
            border-right: 1px solid #dedede;
            margin: 0;
        }

        > .section-information {
            width 100%
            max-width: 100dvw
            padding: 0 21px 0;
            display: flex;
            flex-direction: column;
            gap: 26px;
            margin-top: 24px;

            @media screen and (min-width: 1024px)  {
                margin-top: 60px;
                padding: 0 22px 0;
                max-width: 660px;
                margin-left: auto;
            }
        }
    }

    >.right-container {
        width 100%
        background-color: #fff;

        @media screen and (min-width: 1024px) {
            width 100%
            background-color: $color-grey-light-80;
            min-height: 100dvh;
        }

        >.section-summary {
            width 100%
            max-width: 100dvw
            padding: 21px;

            display flex;
            flex-direction: column;
            gap: 32px;

            position: relative;

            @media screen and (min-width: 1024px) {
                position: sticky;
                top: 72px;

                padding: 38px;
                width 100%
                max-width: 520px;
                margin-right: auto;

                display flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }
}

.order-detail-header{
    display: flex;
    align-items: center;
    gap: 16px;
}

.order-detail-container {
    border: 1px solid $color-gray85
    border-radius: 8px
    padding: 16px

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px

    @media screen and (min-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }

    .span-2 {
        grid-column: span 1

        @media screen and (min-width: 1024px) {
            grid-column: span 2
        }
    }
}

.order-detail-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
}

.section-summary-sticky {
    background-color: $color-grey-light-80;

    grid-column: span 2;
}

.section-summary-sticky-accordion {
    width 100%
    max-width: 100dvw
    padding: 21px 21px 0;

    display flex;
    flex-direction: column;
    gap: 21px;
}

.full-page
 overflow: visible !important


.action-confirm-order-container {
    position sticky
    bottom 0
    width 100%
    background linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
    padding 24px 16px

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: column;

    .ship-date {
        width 100%
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        padding: 80px
        background-color: $color-grey-light-80
        border-radius: 8px
    }
}
</style>
